.login-wrapper {
  background-color: white;
  position: absolute;
  top: 20%;
  left: 35%;
  min-width: 500px;
  max-width: 500px;
  padding: 100px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  --cds-label-01-font-size: 0.9rem;
  --cds-label-01-font-weight: 800;
}

.login-page {
  background-color: #423e5c;
  width: 100vw;
  height: 100vh;
}

.login-title {
  font-family: math;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #625f81;
  color: white;
  width: 100%;
  text-align: center;
}

.cds--text-input {
  border: 1px solid lightgray !important;
  border-radius: 5px;
}

.cds--text-input:focus, .cds--text-input:active {
  outline: 0px !important;
  border: 2px solid !important;
  border-radius: 5px;
}

.resetpwd {
  margin-top: 10px;
}